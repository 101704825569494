import Certificate from "./Certificate";
import { useParams } from "react-router-dom";

const CertificateHandler = () => {
	const { nft_pkey } = useParams();

	if (nft_pkey) {
		return (
			<Certificate nft_pkey={nft_pkey} />
		);
	}
	return null;
}


export default CertificateHandler;