import React from "react";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import { Connection, ParsedAccountData, PublicKey } from "@solana/web3.js";
import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import { SERVER_URL } from "./constants";
import './css/Certificate.css';

var hdate = require('human-date')

interface attribute {
	trait_type: string,
	value: string
}

const Certificate = ({ nft_pkey }: { nft_pkey: string }) => {

	mixpanel.track('QR Certificate', { nft_pkey });

	const [nft_data_url, setNftDataUrl] = useState<string>();
	const [nft_owner_pkey, setNftOwnerPkey] = useState<PublicKey>();

	const [nft_name, setNftName] = useState<string>();
	const [nft_img, setNftImg] = useState<string>();
	const [nft_description, setNftDescription] = useState<string>();
	const [nft_token, setNftToken] = useState<string>();
	const [nft_collection_name, setNftCollectionName] = useState<string>();
	const [nft_collection_family, setNftCollectionFamily] = useState<string>();

	const [nft_attributes, setNftAttributes] = useState<attribute[]>([]);

	const [nft_external_url, setNftExternalUrl] = useState<string>();

	const [nft_owner_name, setNftOwnerName] = useState<string>();
	const [nft_print_date, setNftPrintDate] = useState<string>();


	const logo = "./nft-splash-logo-3.svg"//"https://lh3.googleusercontent.com/7xKkZ9pGg9emGoA8Wl8tc_cc4HmIJKac09aZZG9-3WPnjOnSzrxRM6aUx8gDmptKFHvFNsSaM5cBkDY3ePKop4QmPKICmo7yh4FN7A=s0";

	useEffect(
		() => {
			const conn = new Connection("https://api.mainnet-beta.solana.com");
			conn.getTokenLargestAccounts(new PublicKey(nft_pkey))
				.then(largestAccounts => conn.getParsedAccountInfo(
					largestAccounts.value[0].address)
				)
				.then(largestAccountInfo => (
					largestAccountInfo.value!.data as ParsedAccountData).parsed.info.owner
				)
				.then(address => setNftOwnerPkey(address));


			Metadata.findByMint(conn, nft_pkey)
				.then(r => {
					setNftToken(r.pubkey.toString());
					setNftDataUrl(r.data.data.uri);

				});

		}, [nft_pkey]);

	useEffect(
		() => {
			if (nft_data_url) {
				fetch(nft_data_url)
					.then(res => res.json())
					.then(data => {
						setNftImg(data.image);
						setNftName(data.name);
						setNftDescription(data.description || null);
						setNftExternalUrl(data.external_url || null);
						setNftCollectionName(data.collection.name || null);
						setNftCollectionFamily(data.collection.family || null);
						setNftAttributes(data.attributes);
					})
			}
		}, [nft_data_url]);


	useEffect(
		() => {
			const uri = `${SERVER_URL}/order/mint?userkey=${nft_owner_pkey}&mintkey=${nft_pkey}`;
			fetch(uri)
				.then(res => res.json())
				.then(data => {
					setNftPrintDate(data.date); // humanize this date
					setNftOwnerName(data.address.name);
				})
				.catch(
					err => console.log(err)
				);
		}, [nft_owner_pkey]
	);

	// TODO
	// perhaps it's better to accept both the pkey of the user and the nft so we can display a different flow if the user simply sent their nft to someone else


	// set up routing later

	return (
		<div className="certificate">
			<div className="navbar">
				<div className="navbar-logo">
					<img src={logo} alt="logo" className="logo" />
				</div>
			</div>

			<div className="certificate-container">
				<div className="certificate-content-container">


					<div className="certificate-container-image">
						<div className="certificate-title-container">
							<h1 className="certificate-title"><a href={nft_external_url}>{nft_name}</a></h1>
							<h3 className="certificate-subtitle">{nft_collection_name}{nft_collection_family ? `, ${nft_collection_family}` : null}</h3>
						</div>
						<img src={nft_img} alt="NFT" />
					</div>
					<div className="certificate-owner-container">
						<h2>Owner</h2>
						{nft_owner_name && <h3 className="certificate-owner-name">{nft_owner_name}</h3>}
						<div className="pkey">
							{nft_owner_pkey}
						</div>

						{
							nft_print_date &&
							<div className="art-print-date">
								<strong>Art Print issued on: {hdate.prettyPrint(nft_print_date)}</strong>
							</div>
						}
					</div>

					<div className="certificate-about-container">
						<h2>About</h2>
						{nft_description && <div className="about-description">{nft_description}</div>}

						<div className="about-address-container">
							<div className="about-address">
								<h4 className="about-address-title">Mint</h4>
								<div className="about-address-pkey pkey">{nft_pkey}</div>
							</div>
							<div className="about-address">
								<h4 className="about-address-title">Token</h4>
								<div className="about-address-pkey pkey">{nft_token}</div>
							</div>
						</div>

					</div>
					{
						nft_attributes.length > 0 &&

						<div className="about-attribute-container">
							<h2 className="about-attribute-title">
								Attributes
							</h2>
							<div className="about-attribute-content-container">
								{
									nft_attributes.map((attribute) => (
										<div className="about-attribute-content" key={attribute.trait_type}>
											<div className="about-attribute-content-trait">
												{attribute.trait_type}
											</div>
											<div className="about-attribute-content-value">
												{attribute.value}
											</div>
										</div>
									))
								}
							</div>


						</div>
					}
				</div>
			</div>
		</div>
	);

}

export default Certificate;